export const patientGTI: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  banner: "Banner - HTML - GTB - DTC - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  footerRef: "Reference - GTB - DTC - Retina Spain",
  infographic: "Infographic - GTB - DTC - Retina Spain",
  footerCallout: "Footer Callout GTB - DTC - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  moreAnswersSection: "More answers HTML - GTB - DTC - Retina Spain",
  over270Genes: "Over 270 Genes HTML - GTB - DTC - Retina Spain",
  earlyTesting: "Early genetic testing HTML - GTB - DTC - Retina Spain",
  prepareToSpeak: "Speak with eye specialist - GTB - DTC - Retina Spain",
  GTInconclusive: "Genetic Testing Inconclusive - IRD - DTC - Retina Spain",
  backToTopGTM: "back to top - genetic testing benefits",
  footerClassName: "genetic-testing-importance-footer"
}
