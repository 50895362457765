import React, { useRef, useEffect } from 'react';
import { CircularInfographics, GenTestBenefitWrapperMain, HTMLRenderer, Layout, PageWrapper } from '@retina-packages/retina-theme-bootstrap';
import { graphql } from 'gatsby';
import { ContainerWrapper, EarlyTestingBenefit, EmerFooterBlock, EsGeneticTestingBenefitWrapper, LeftImagePoints, MoreAnswerTestingBenefit, TestorRetestPatientSectionWrapper } from './styles'
import "react-circular-progressbar/dist/styles.css";
import {
  FooterCalloutBlock,
  EsHeaderContainer,
  HomePageFooterContainer
} from '../../../css/common/style'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { patientGTI } from './constants';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

/** Renders genetic-testing-importance-page template
 *
 * @param props props
 * @returns
 */
const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;
  const pgContextsGTB = props.pageContext
  const htmlStaticFilesGTB = {
    nonSVGImages: pgContextsGTB.nonSVGImages,
    svgMediaImages: pgContextsGTB.svgMediaImages,
    allMediaDocument: pgContextsGTB.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientGTI.mainMenu, siteFooterMenu: patientGTI.footerMenu });

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientGTI.footer
  const exitPopupTitle = patientGTI.exitPopup
  const hcpSwitcherTitle = patientGTI.hcpSwitcher
  const topNavTitle = patientGTI.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const infographicsTitle = patientGTI.infographic
  const infographicData: any = blocks.filter((inf: any) => inf.relationships.field_reusable_paragraph.label === infographicsTitle).map(
    (info: any) => info.relationships.field_reusable_paragraph.relationships.paragraphs.relationships.field_paragraph_items_gcso
  ).at(0);

  const donutInfoGraData = {
    per: infographicData[0].field_para_text_gcso.value,
    circular_text: infographicData[1].field_para_text_gcso.value,
    right_text: infographicData[2].field_para_text_gcso.value
  }


  const eyeSpHTMLData = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.prepareToSpeak})
  const htmlData = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.GTInconclusive})
  const over270GenesHTML = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.over270Genes})
  const siteLogos = deriveMultiLogo({ blocks: blocks, title: patientGTI.siteLogo})
  const footerCalloutGTBHTML = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.footerCallout})
  const bannerHTML = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.banner})
  const moreAnswerTestingBenefitHTML = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.moreAnswersSection})
  const earlyTestHTML = fullHTMLDataBuilder({blocks: blocks, title: patientGTI.earlyTesting})
  const footerRefHTML = footRefHTMLBuilder({blocks: blocks, title: patientGTI.footerRef})

  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        observer.unobserve(domRef.current);
      }
    });
    observer.observe(domRef.current);

    const pageClass = 'genetic-testing-benefit-page'
    document.body.className = pageClass
  }, []);

  return (
    <>
      <EsHeaderContainer>
        <EsGeneticTestingBenefitWrapper className="gentestbenfwrapper">
          <HomePageFooterContainer>
            <GenTestBenefitWrapperMain className='genetic-testing-wrapper-main genetic-testing-benifit-wrapper'>
              {props.pageContext !== null && props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
              <Layout
                title={"siteTitle"}
                location={props.location}
                data={mainMenu}
                mdata={footerMenu}
                footerData={footerText}
                audience={audience}
                exitPopData={exitData}
                hcplinks={hcpSwitcher}
                topNavigation={topNavs}
                siteLogos={siteLogos}
                staticMediaFiles={htmlStaticFilesGTB}
                gtmBackToTopLabel={patientGTI.backToTopGTM}
                backToTopContent={retinaConfig.backToTopContent}
                modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
                hcpValidate={retinaConfig.hcpValidate}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
                preIndexUrl={retinaConfig.preIndexUrl}
                hcpPrefix={retinaConfig.hcpPrefix}
                siteHomeUrl={retinaConfig.siteHomeUrl}
                footerClassName={patientGTI.footerClassName}
                footref={footerRefHTML}
                showRefContent={retinaConfig.showRefContent}
                hideRefContent={retinaConfig.hideRefContent}
                cpraFooter={true}
                cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
                cpraOT={true}
                cpraOTEnv={process.env.OT_ENV}
                cpraOTuuid={process.env.OT_UUID}
                {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
              >
                <PageWrapper className='pagewrapper generic-testing-benefits'>
                  <MobileBanner className="mobile-banner clinical-trials-banner">
                    <HTMLRenderer data={htmlStaticFilesGTB} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                  </MobileBanner>
                  {/*Retina Canada Patients - "Importance of Genetic Testing" page -Speak with your eye specialist Component- EN*/}
                  <MoreAnswerTestingBenefit>
                    <HTMLRenderer html={moreAnswerTestingBenefitHTML} data={htmlStaticFilesGTB} tagName='div' className='fullwidth-listing o-top-space o-bottom-space testing-benefits-listing' />
                  </MoreAnswerTestingBenefit>
                  <ContainerWrapper  className='container genes-wrapper'>
                    <TestorRetestPatientSectionWrapper>
                      <HTMLRenderer html={over270GenesHTML} data={htmlStaticFilesGTB} tagName='div' className='o-container' />
                    </TestorRetestPatientSectionWrapper>
                  </ContainerWrapper>
                  {/*Retina Canada Patient - "Importance of Genetic Testing" page -More Answers & Infographic Component-EN*/}
                  <div ref={domRef}>
                    <CircularInfographics
                      data={donutInfoGraData}
                      audience={audience}
                      filedata={htmlStaticFilesGTB}
                    />
                  </div>
                  <EarlyTestingBenefit>
                    <HTMLRenderer html={earlyTestHTML} data={htmlStaticFilesGTB} className='o-section--cover-bg testing-list-bg' tagName='div' />
                  </EarlyTestingBenefit>
                  <HTMLRenderer data={htmlStaticFilesGTB} html={eyeSpHTMLData} />
                  <LeftImagePoints><HTMLRenderer data={htmlStaticFilesGTB} html={htmlData} /></LeftImagePoints>
                  {/* Retina Canada Patients - Importance of Genetic Testing page -Footer callout Component - EN */}
                  <EmerFooterBlock  className='genes-wrapper imp-gens emr-footer-block'>
                    <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                        <HTMLRenderer html={footerCalloutGTBHTML} data={htmlStaticFilesGTB} tagName='div' className='footer-block footer-block-bg' />
                    </FooterCalloutBlock>
                  </EmerFooterBlock>
                </PageWrapper>
              </Layout>
            </GenTestBenefitWrapperMain>
          </HomePageFooterContainer>
        </EsGeneticTestingBenefitWrapper>
      </EsHeaderContainer>
    </>
  )
};

export default GeneticTestingBenefitTemplate;

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... GTIPageQuery
      }
    }
  }
`
